import React, { useState } from "react";
import BackButton from "../../basic/BackButton";
import Header from "../../master/Header";
import { Link } from "react-router-dom";
import IconButton from "../../basic/IconButton";
import { FaInstagram } from "react-icons/fa";
import CommonButton from "../../basic/CommonButton";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import UserDetails from "./UserDetails";
import SalesComp from "./SalesComp";

//chats js
import { Line } from "react-chartjs-2";
import SelectOption from "../../forms/SelectOption";
import CardBox from '../../basic/CardBox'
import Modal from "react-modal/lib/components/Modal";
import Forminput from '../../forms/FormInput'
import AddImage from "./AddImage";


const PromoteNewItem = () => {
    const [images, setImages] = useState([]);
    const handleMultipleImages = (evnt) => {
        const selectedFIles = [];
        const targetFiles = evnt.target.files;
        const targetFilesObject = [...targetFiles]
        targetFilesObject.map((file) => {
            return selectedFIles.push(URL.createObjectURL(file))
        })
        setImages(selectedFIles);
    }



    const salesItem = [
        {
            id: 1,
            name: "Miguel Sanchez",
            follower: "22k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 2,
            name: "Miguel Sanchez",
            follower: "13k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 3,
            name: "Miguel Sanchez",
            follower: "10k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 4,
            name: "Miguel Sanchez",
            follower: "4.7k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 5,
            name: "Miguel Sanchez",
            follower: "4.7k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 6,
            name: "Miguel Sanchez",
            follower: "4.7k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },
    ]

    const userDetail = [
        {
            id: 1,
            img: "/images/user.png",
            name: "Migule Sanchez",
            status: "Acepted",
        },

        {
            id: 2,
            img: "/images/user.png",
            name: "Migule Sanchez",
            status: "Acepted",
        },

        {
            id: 3,
            img: "/images/user.png",
            name: "Migule Sanchez",
            status: "Acepted",
        },

        {
            id: 4,
            img: "/images/user.png",
            name: "Migule Sanchez",
            status: "Acepted",
        },
    ]


     
const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },
      {
        label: "Second dataset",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#742774"
      }
    ]
  };

    //select option 
    const options = [
        { id: 1, value: 'Miguel Sanchez1', label: 'Miguel Sanchez1' },
        { id: 2, value: 'Miguel Sanchez2', label: 'Miguel Sanchez2' },
        { id: 3, value: 'Miguel Sanchez', label: 'Miguel Sanchez' }
    ]

    //modal 
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <main className="d-flex main-bg PromoteNewitem have-header">
                <Header />
                <div className="container-fluid px50">
                 
                        <Link to="/brand/dashboard"> <BackButton /> </Link>
                

                    <CardBox>
                        <div className="row">
                            <div className="col-lg-4">
                                <h1 className="page-title pb-0 mb-0">Promote New Air Jordans</h1>
                                <h6 className="date">Date - <span className="color"> 20/02/2022</span></h6>

                            </div>

                            <div className="col-lg-8 text-lg-end">
                                <CommonButton name="Campaign Option" onClick={openModal} className="me-3" />
                                <IconButton text="Share on Instagram" icon={<FaInstagram />} />
                            </div>
                        </div>
                    </CardBox>

                    <CardBox className="p-1">
                        <div className="col-12">
                            <div className="details">
                                <div className="text-section">
                                    <h3>Pending Proposals</h3>
                                    <p>4</p>
                                </div>
                                <div className="text-section">
                                    <h3>Sales</h3>
                                    <p>4</p>
                                </div>

                                <div className="text-section">
                                    <h3>Views</h3>
                                    <p>4</p>
                                </div>

                                <div className="text-section">
                                    <h3>Conversion Rate</h3>
                                    <p>4</p>
                                </div>

                                <div className="text-section">
                                    <h3>Total Profit</h3>
                                    <p>4</p>
                                </div>

                                <div className="text-section">
                                    <h3>Pending Proposals</h3>
                                    <p>4</p>
                                </div>
                            </div>
                        </div>
                    </CardBox>


                    <CardBox className="card-bg">

                        <div className="col-12">
                            <Tabs>
                                <TabList>
                                    <Tab>Campaign Info</Tab>
                                    <Tab>Sales</Tab>
                                    <Tab>Performance</Tab>
                                </TabList>

                                {/* Campaign Info Start*/}
                                <TabPanel>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="img-section">
                                                <img src="/images/user.png" className="w-100" alt="" />
                                            </div>
                                        </div>

                                        <div className="col-lg-5">
                                            <div className="message-area">
                                                <div className="msg">
                                                    <h3>Campaign Brief</h3>
                                                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti, id atque, optio sunt et, saepe pariatur voluptas accusantium libero blanditiis illo officiis sapiente cupiditate! Optio delectus eos perspiciatis odit sint?</p>
                                                </div>

                                                <div className="msg">
                                                    <h3>Comission</h3>
                                                    <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti, id atque, optio sunt et, saepe pariatur voluptas accusantium libero blanditiis illo officiis sapiente cupiditate! Optio delectus eos perspiciatis odit sint?</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="working-on-it">
                                                <h3>Working on it</h3>

                                                <div className="row mx-0">
                                                    {
                                                        userDetail.map(item => (
                                                            <UserDetails key={item.id} {...item} />
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </TabPanel>

                                {/* Sales Info Start*/}
                                <TabPanel>
                                    <div className="row">

                                        <div className="col-md-3">
                                            <SelectOption options={options} />
                                        </div>

                                        <div className="col-12">
                                            {
                                                salesItem.map(item => (
                                                    <SalesComp key={item.id} {...item} />
                                                ))
                                            }
                                        </div>
                                    </div>
                                </TabPanel>

                                {/*Performance*/}
                                <TabPanel>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="col-md-10">
                                                <SelectOption options={options} />

                                                <div className="revenue">
                                                    <div className="revenue-detail">
                                                        <h4>Total Revenue</h4>
                                                        <p>$2580</p>
                                                    </div>

                                                    <div className="revenue-detail">
                                                        <h4>Total Commissions</h4>
                                                        <p>$2580</p>
                                                    </div>

                                                    <div className="revenue-detail">
                                                        <h4>Conversion Rate</h4>
                                                        <p>50%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-8">
                                            <Line data={data} />
                                        </div>

                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </CardBox>
                </div>


                <Modal  ariaHideApp={false} className="addImgForm" isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal">
                    <h2>Edit Campaign </h2>
                    <div className="row">

                        <div className="col-lg-6">
                            <Forminput name="Campaign Name" label="Campaign Name" />
                        </div>

                        <div className="col-lg-6">
                            <Forminput name="Deadline" label="Deadline" />
                        </div>

                        <div className="col-lg-12">
                            <div className="image-label">
                                <label htmlFor="" >Campaign Media</label>
                            </div>

                            <div className="imageSection">
                                <div className="img-display">

                                <AddImage images={images} />

                                    <div className="imgAdd">
                                        <label htmlFor="image">
                                        <input type="file" id="image" onChange={handleMultipleImages} accept="image/png, image/gif, image/jpeg" multiple/>
                                            <span className="addName">
                                                +
                                            </span>
                                        </label>
                                    </div>

                                </div>


                            </div>
                        </div>

                        <div className="col-12">
                            <div className="campBrife">
                                <h3>Campaign Brief</h3>
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium ratione, libero vero assumenda deleniti dignissimos reprehenderit et ad expedita quidem asperiores velit totam maiores, rerum ducimus? Nemo eaque facere dicta. </p>
                            </div>
                        </div>

                    </div>
                    <CommonButton name="End Campaign Now" />
                    <CommonButton name="Save Changes" onClick={closeModal}/>
                </Modal>


            </main>
        </>
    );
}

export default PromoteNewItem;