import React from "react";
import BackButton from "../../basic/BackButton";
import CommonButton from "../../basic/CommonButton";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const SripeAccount = () =>{

     // button link
     let navigate = useNavigate();
     const shopifyIntergrationLink = () => {let path = '/brand/shopifyIntergration';navigate(path);}
    return(
        <>
            <main className="main-bg sripeAccount d-flex">
                <section className="w-100 align-self-center">
                    <div className="container-fluid px50">
                        <div className="row">
                        
                            <Link to="/app/profile-info"> <BackButton  /> </Link>
                         
                            <div className="col-lg-12 mx-auto">
                                <div className="card-bg text-center card-center">
                                    <div className="w-100">


                                        <h1>Connect With Your Sripe Account</h1>
                                        <CommonButton name="Connect" onClick={shopifyIntergrationLink} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default SripeAccount;