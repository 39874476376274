import React from "react";
import { Link } from "react-router-dom";


const CamaingsSection = (props) => {


    var images = props.images;


    return (
        <>
            <Link  to={{pathname:`/${props.link}`}}>
                <div className="campaings-section" onClick={props.click}>
                    <div className="capm-name">
                        <h4> {props.title}</h4>
                        <span className="date">{props.date}</span>
                    </div>
                    <div className="camp-post-section">
                        <ul className="list-unstyled camp-post">
                            <li>
                                <h6 className="head">Pending Proposals</h6>
                                <p className="num">{props.pendingProposals}</p>
                            </li>

                            <li>
                                <h6 className="head">Sales</h6>
                                <p className="num">{props.sales}</p>
                            </li>

                            <li>
                                <h6 className="head">Views</h6>
                                <p className="num">{props.views}</p>
                            </li>

                            <li>
                                <h6 className="head">Conversion Rate</h6>
                                <p className="num">{props.conversionRate}%</p>
                            </li>

                            <li>
                                <h6 className="head">Total Profit</h6>
                                <p className="num">${props.totalProfit}</p>
                            </li>
                        </ul>
                    </div>

                    <div className="working-section">
                        <div className="woring-main">
                            <h6 className="woring-on">Working on it ({props.workingOn})</h6>
                            <div className="img-section">

                                {
                                    images.map(imgItem => (
                                        <img src={imgItem.url} key={imgItem.id} alt="" />
                                    ))
                                }

                            </div>
                        </div>

                        <div className="working-cost">
                            <h6>{props.workingCost}</h6>
                            <p className="num">${props.wrokingCostNum}</p>
                        </div>
                    </div>

                </div>
            </Link>
        </>
    );
}

export default CamaingsSection;