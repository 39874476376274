import React from "react";

const UserDetails = (props) => {
    return (
        <>
            <div className="col-lg-6">
                <div className="user-id">
                    <img src={props.img} alt="" />
                    <div className="user-dtails">
                        <h2 className="name">{props.name} </h2>
                        <span className="status">{props.status}</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserDetails;
