import React, {useState} from "react";
import CardBox from "../../basic/CardBox";
import Header from "../../master/Header";
import NotificationCom from "./NotificationCom";
import Select from 'react-select'
/*chart*/
import { Line } from "react-chartjs-2";
import Chart from 'chart.js/auto';

import BackButton from "../../basic/BackButton";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

const PerformanceOverview = () => {

    /*select tag*/
    const options = [
        { value: 'New Jordan Air', label: 'New Jordan Air' },
        { value: 'New Jordan Air2', label: 'New Jordan Air2' },
        { value: 'New Jordan Air3', label: 'New Jordan Air3' }
    ]

    /*Chart*/
    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        datasets: [
            {
                label: "Your Stats",
                data: [33, 53, 85, 41, 44, 65],
                fill: false,
                borderColor: "rgba(75,192,192,1)"
            },
            {
                label: "Industry Standrads",
                data: [33, 25, 35, 51, 54, 76],
                fill: false,
                borderColor: "#742774"
            }
        ]
    };

 
    /* Date calendra*/
    const [value, onChange] = useState([new Date(), new Date()]);

    return (
        <>
            <main className="d-flex main-bg performanceOverview have-header">
                <Header />
                <div className="container-fluid px50">
                    <BackButton />
                    <CardBox>
                        <div className="row">
                            <div className="col-12">
                                <h2 className="page-title">Overview</h2>
                            </div>
                            <NotificationCom title="Total Profit" detail="$3,265.720" className="pink" link="#" />
                            <NotificationCom title="Influnecers Commissions" detail="$3,265.720" className="purpel" link="#" />
                            <NotificationCom title="Total Campaigns Completed" detail="75" className="yellow" link="#" />
                        </div>
                    </CardBox>

                    <div className="row">
                        <div className="col-lg-2">
                            <CardBox className=" text-center">
                                <h3 className="box-title">Total Profits</h3>
                                <p className="detail">$ 650</p>
                            </CardBox>
                        </div>

                        <div className="col-lg-2">
                            <CardBox className=" text-center">
                                <h3 className="box-title">Commission</h3>
                                <p className="detail">$ 347</p>
                            </CardBox>
                        </div>

                        <div className="col-lg-2">
                            <CardBox className=" text-center">
                                <h3 className="box-title">Total Sales</h3>
                                <p className="detail">99</p>
                            </CardBox>
                        </div>

                        <div className="col-lg-3">
                            <CardBox className=" text-center">
                                <h3 className="box-title"> Conversion Rate Av.</h3>
                                <p className="detail">40</p>
                            </CardBox>
                        </div>

                        <div className="col-lg-3">
                            <CardBox className=" text-center">
                                <h3 className="box-title">Active Influencers</h3>
                                <p className="detail">32</p>
                            </CardBox>
                        </div>
                    </div>

                    <CardBox className="overflow-inherit h500">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <Select options={options} placeholder="New Jordan Air" />
                                    </div>
                                    <div className="col-lg-3">
                                    <DateRangePicker onChange={onChange} value={value} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 pt-4">
                                <Line data={data} />
                            </div>
                        </div>
                    </CardBox>


                </div>
            </main>
        </>
    );
}

export default PerformanceOverview;