import React from "react";
import { Link } from "react-router-dom";
import { FaEnvelope, FaInstagram} from "react-icons/fa";

const MyInfluencersComp = (props) => {
    return (
        <>        
            <div className="col-md-3">
                <Link to={{ pathname: `/${props.link}` }}>
                    <div className="user-card">
                        
                       <div className="profilearea">
                       <img src={props.image} className="profile" alt="" />
                       </div>
                        <div className="body-area">
                            <h4 className="name">{props.name}</h4>
                            <span className="area">{props.location}</span>
                            <p className="post">Life Style - Fashion</p>
                            

                            <ul className="list-unstyled">
                                <li><FaEnvelope/> {props.email}</li>
                                <li><FaInstagram/>{props.instaid}</li>
                            </ul>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}

export default MyInfluencersComp;