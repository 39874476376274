import React from "react";

const PendingProposalByCom = (props) => {
    return (
        <>
            <div className="col-lg-6">
                <div className="img-box">
                    <img src={props.img} alt="" />
                    <div className="text-container">
                        <h3>{props.title}  <span>Campaign</span></h3>
                        <div className="d-flex justify-content-between">
                            <span className="fitem"> Sales <strong className="d-block"> {props.sales} </strong></span>
                            <span className="fitem"> Engagement  <strong className="d-block text-end">{props.engament}% </strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PendingProposalByCom;