import React from "react";
import ReactPaginate from 'react-paginate';

import { TiChevronRight, TiChevronLeft } from "react-icons/ti";

const PaginatedItems = (props) => {

    return (
        <>
            <div className={"pagingNation " + props.className}>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={<TiChevronRight />}
                    onPageChange={props.handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={props.pageCount}
                    previousLabel={<TiChevronLeft />}
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    );
}

export default PaginatedItems;