import React from "react";
import Header from "../../master/Header";
import BackButton from "../../basic/BackButton";
import { Link } from "react-router-dom";
import PendingProposalComp from "./PendingProposalComp";

const PendingProposal = () => {

    const products = [
        {
            id: 1,
            link: "brand/pending-proposal-by",
            title: "Promote New Air Jordans",
            img: "/images/demo-img1.jpg",
            comission: 8.5,
            date: "15/07/2022"
        },

        {
            id: 2,
            link: "brand/pending-proposal-by",
            title: "Promote New Air Jordans 2",
            img: "/images/demo-img2.jpg",
            comission: 8.5,
            date: "15/07/2022"
        },

        {
            id: 3,
            link: "brand/pending-proposal-by",
            title: "Promote New Air Jordans 3",
            img: "/images/demo-img3.jpg",
            comission: 8.5,
            date: "15/07/2022"
        },

        {
            id: 4,
            link: "brand/pending-proposal-by",
            title: "Promote New Air Jordans 4",
            img: "/images/demo-img1.jpg",
            comission: 8.5,
            date: "15/07/2022"
        }



    ]

    return (
        <>

            <main className="d-flex main-bg newSales have-header">
                <Header />
                <div className="container-fluid px50">
                   
                        <Link to="/brand/dashboard"> <BackButton /> </Link>
                     
                    <div className="card-bg">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="page-title">Pending From Proposal</h2>
                            </div>
                            {
                                products.map(product => (
                                    <PendingProposalComp key={product.id} {...product} />
                                ))
                            }
                        </div>

                    </div>
                </div>
            </main>
                      
        </>
    );
}

export default PendingProposal;