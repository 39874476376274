import React from "react";
import CardBox from "../../basic/CardBox";
import CommonButton from "../../basic/CommonButton";
import PaginatedItems from "../../basic/PaginatedItems";
import FromInput from "../../forms/FormInput";
import { ActiveCamp } from "../../../../data/ActiveCamp"
import Header from "../../master/Header";
import ActiveCampaingsComp from "./ActiveCampaingsComp";
import BackButton from "../../basic/BackButton";
import { Link } from "react-router-dom";

const ActiveCampaings = () => {
    const activeCamp = ActiveCamp();

    const handleOnPageClick = () => {
        console.info("on Page Clicked");
    }

    return (
        <>
            <main className="d-flex main-bg activeCampaning dashboard have-header">
                <Header />

                <div className="container-fluid px50 ">
                    <div className="row">
                      
                            <Link to="/brand/dashboard"> <BackButton /> </Link>
                      

                        <div className="col-12">
                            <CardBox className="mb-0">
                                <div className="row mb-3">
                                    <div className="col-md-6 align-self-center">
                                        <h2 className="page-title mb-0">Active Campaings</h2>
                                    </div>

                                    <div className="col-md-6  ">
                                        <div className="d-sm-flex search-section justify-content-end">
                                            <FromInput className="hide-label mb-0 " placeholder="Search Campaign" />
                                            <CommonButton name="Publish Campaign" />
                                        </div>
                                    </div>
                                </div>

                                <div className="campaings-details">
                                    {
                                        activeCamp.map(item => (
                                            <ActiveCampaingsComp key={item.id} {...item} images={item.img} />

                                        ))
                                    }
                                </div>

                                <div className="text-end">
                                    <PaginatedItems className="justify-content-end" handlePageClick={handleOnPageClick} pageCount={50} />
                                </div>

                            </CardBox>
                        </div>

                    </div>
                </div>
            </main>
        </>
    );
}
export default ActiveCampaings;