import React from "react";

const Notification = (props) => {

    return (
        <>
            <div className={"notification-box " + props.className}>          
                <div className="text-section">
                    <p>{props.title}</p>
                    <h2>{props.number}</h2>
                    {props.icon}
                </div>
            </div>
        </>
    );

}

export default Notification;