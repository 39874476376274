import React from "react";

import BackButton from "../../basic/BackButton";
import CommonButton from "../../basic/CommonButton";
import FromInput from "../../forms/FormInput";
import { useNavigate } from "react-router-dom"; 
import { Link } from "react-router-dom";
 

const AddYourCard = () => {

      
   let navigate = useNavigate(); 

   // button link
   const dashboardLInk = () =>{ 
     let path = '/brand/dashboard'; 
     navigate(path);
   }


    return (
        <>
               <main className="main-bg shopify d-flex">
                <section className="w-100 align-self-center">
                    <div className="container-fluid px50">
                        <div className="row">
                    
                            <Link to="/brand/shopifyIntergration"> <BackButton /> </Link>
                 
                            <div className="col-lg-12 mx-auto">
                                <div className="card-bg text-center card-center">
                                    <div className="row w-100">                                      
                                        <div className="col-12 mb-4">
                                            <h1 className="pb-0 ">Add Your Credit Or Debit Card</h1>
                                            <p className="col-md-5 mx-auto">You will not be charged For joining Influencer payment is automa for every sale, Plus a 5% Fee</p>
                                        </div>

                                        <div className="col-md-6">
                                            <FromInput type="text" placeholder="Name on card" name="Name on card"/>
                                        </div>
                                        <div className="col-md-6">
                                            <FromInput type="number" placeholder="Credit Card Number" name="Credit Card Number"/>
                                        </div>
                                        <div className="col-md-6">
                                            <FromInput type="text" placeholder="Exp Date" name="Exp Date"/>
                                        </div>
                                        <div className="col-md-6">
                                            <FromInput type="text" placeholder="CVV" name="CVV"/>
                                        </div>

                                        <div className="col-12 mt-4">
                                        <CommonButton name="Next" onClick={dashboardLInk}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>   
        </>
    );
}

export default AddYourCard;