
import './App.scss';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from './components/login/Login';
import Register from './components/login/Register';
import AccountType from './components/ui/pages/accountType/AccountType';
import BrandInfo from './components/ui/pages/brand/BrandInfo';
import Shopify from './components/ui/pages/shopify/Shopify';
import ShopifyIntergration from './components/ui/pages/shopifyIntergration/ShopifyIntergration';
import AddYourCard from './components/ui/pages/addYourCard/AddYourCard';
import Dashboard from './components/ui/pages/dashboard/Dashboard';
import NewSales from './components/ui/pages/newSales/NewSales';
import PendingProposal from './components/ui/pages/pendingProposal/PendingProposal';
import PendingProposalBy from './components/ui/pages/pendingProposalBy/PendingProposalBy';
import PromoteNewItem from './components/ui/pages/promoteNewItem/PromoteNewItem';
import ProfileInfo from './components/ui/pages/ProfileInfo/ProfileInfo';
import SripeAccount from './components/ui/pages/sripeAccount/SripeAccount';
import ActiveCampaings from './components/ui/pages/ActiveCampaings/ActiveCampaings';
import MyInfluencers from './components/ui/pages/myInfluencers/MyInfluencers';
import PerformanceOverview from './components/ui/pages/performance/PerformanceOverview';
import CampaignsHistory from './components/ui/pages/PerformanceCampaignsHistory/CampaignsHistory';
 
function App() {
  return (
    <>
      <Router> 
          <Routes>

             <Route exact path="/" element={<Login />}/>    
             <Route exact path="/app/register" element={<Register/>} />    
             <Route exact path="/app/accountType" element={<AccountType/>} />   
             <Route exact path="/app/brandInfo" element={<BrandInfo/>} />   
             <Route exact path="/app/profile-info" element={<ProfileInfo/>} />  
             <Route exact path="/brand/shopify" element={<Shopify/>} />
             <Route exact path="/brand/sripe-account" element={<SripeAccount/>} />
             <Route exact path="/brand/shopifyIntergration" element={<ShopifyIntergration/>} />
             <Route exact path="/brand/addYourCard" element={<AddYourCard/>} />
             <Route exact path="/brand/dashboard" element={<Dashboard/>} />
             <Route exact path="/brand/new-sales" element={<NewSales/>} />
             <Route exact path="/brand/pendingProposal" element={<PendingProposal/>} />
             <Route exact path="/brand/pending-proposal-by" element={<PendingProposalBy/>} />        
             <Route exact path="/brand/promoteNewItem" element={<PromoteNewItem/>} />
             <Route exact path="/brand/activeCampaings" element={<ActiveCampaings/>} /> 
             <Route exact path="/brand/Influencers" element={<MyInfluencers/>} /> 
             <Route exact path="/brand/PerformanceOverview" element={<PerformanceOverview/>}/> 
             <Route exact path="/brand/CampaignsHistory" element={<CampaignsHistory/>}/> 
          </Routes> 
      </Router>
      
    </>
  );
}

export default App;
