import React from "react";
import Header from "../../master/Header";
import SalesComponents from "./SalesComponents";

import BackButton from "../../basic/BackButton";
import { Link } from "react-router-dom";

const NewSales = () => {

    const salesItem = [
        {
            id: 1,
            name: "Miguel Sanchez",
            follower: "22k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 2,
            name: "Miguel Sanchez",
            follower: "13k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 3,
            name: "Miguel Sanchez",
            follower: "10k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 4,
            name: "Miguel Sanchez",
            follower: "4.7k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 5,
            name: "Miguel Sanchez",
            follower: "4.7k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 6,
            name: "Miguel Sanchez",
            follower: "4.7k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 7,
            name: "Miguel Sanchez",
            follower: "4.7k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },

        {
            id: 8,
            name: "Miguel Sanchez",
            follower: "4.7k",
            product: "Air Jordan",
            productPrice: "150",
            debt: 15,
            date: "10/12/2021",
        },
    ]



    return (
        <>
            <main className="d-flex main-bg newSales have-header">
                <Header />
                <div className="container-fluid px50">

                    <Link to="/brand/dashboard"> <BackButton/> </Link>

                    <div className="card-bg">
                        <div className="col-12">
                            <h2 className="page-title">New Sales</h2>
                        </div>
                        <div className="seals-section">

                            {
                                salesItem.map(item => (
                                    <SalesComponents key={item.id} {...item} />
                                ))
                            }

                        </div>

                    </div>
                </div>
            </main>
        </>
    );
}

export default NewSales;