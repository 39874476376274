import React from "react";
import BackButton from "../../basic/BackButton";
import CommonButton from "../../basic/CommonButton";
import FromInput from "../../forms/FormInput";
import FormTextarea from "../../forms/FormTextarea";
import FromInputFile from "../../forms/FromInputFile";

import { useNavigate } from "react-router-dom"; 
import { Link } from "react-router-dom";

const ProfileInfo = () => {

    // button link
    let navigate = useNavigate();

    const sripeAccountLink = () => {
        let path = '/brand/sripe-account';
        navigate(path);
    }


    return (
        <>
            <main className="main-bg">
                <section className="brandinfo-area">
                    <div className="container-fluid px50">
                        <div className="row">


                             
                                <Link to="/app/accountType"> <BackButton  /> </Link>
                            
                            <div className="col-md-12 mx-auto">
                                <div className="brand-form card-bg">

                                    <div className="row">

                                        <div className="col-12">
                                            <h1> Profile Info</h1>
                                        </div>
                                        <div className="col-md-6">
                                            <FromInput type="text" placeholder="Full Name" name="Full Name" />
                                        </div>
                                        <div className="col-md-6">
                                            <FromInput type="date" placeholder="Date of Birth" name="Date of Birth" />
                                        </div>
                                        <div className="col-md-6">
                                            <FromInput type="text" placeholder="Gender" name="Gender" />
                                        </div>
                                        <div className="col-md-6">
                                            <FromInput type="text" placeholder="Category" name="Category" />
                                        </div>
                                        <div className="col-md-6">
                                            <FromInput type="text" placeholder="Location" name="Location" />
                                        </div>

                                        <div className="col-md-6">
                                            <FromInput type="number" placeholder="Phone Number" name="Phone Number" />
                                        </div>


                                        <div className="col-md-6">
                                            <FromInputFile name="Add Profile Picture" />
                                        </div>

                                        <div className="col-md-6">
                                            <FormTextarea placeholder="Bio" name="Bio" />
                                        </div>

                                        <div className="col-md-12">
                                            <CommonButton type="button" name="Next" onClick={sripeAccountLink} />
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </div>

                    </div>
                </section>
            </main>
        </>
    );
}

export default ProfileInfo