import React from "react";
import CommonButton from "../ui/basic/CommonButton";
import FromInput from "../ui/forms/FormInput";
import { Link } from "react-router-dom";
import { FaLinkedin, FaGoogle } from 'react-icons/fa';

const Register = () =>{



  return(
      <>
          <section className="login-bg register">
                <div className="login-area text-center">
                    <h1>Register</h1>
                    <FromInput type="email" name="Email" placeholder="Email"/>
                    <FromInput type="password" name="Password" placeholder="Password"/>
                    <FromInput type="password" name="Repeat Password" placeholder="Repeat Password"/>
                    <div className="row loginwith pb-3">
                        <div className="col-sm-6"> <div className="join-with"> <FaLinkedin/> Join with Linkdin </div></div>
                        <div className=" col-sm-6"><div className="join-with text-end">  <FaGoogle/> Join with Google </div> </div>
                    </div>
                     
                    <Link to="/app/accountType">
                       <CommonButton type="button" name="Sign UP"/>
                   </Link>

                    <p className="account">Already Have An Account <Link to="/"> Login</Link></p>
                </div>
            </section>
      </>
  );
}

export default Register;