import React from "react";
import CommonButton from "./CommonButton";
import { useNavigate } from "react-router-dom";
import CardBox from "./CardBox";


const SideNav = () => {
    //modal button link
    let navigate = useNavigate();
    const newSalesLink = () => { let path = '/brand/new-sales'; navigate(path); }

    return (
        <>
            <CardBox className="side-details flex-wrap mb-0">

                <div className="profile-section">
                    <div className="profile-img">
                        <img src="/images/user_man.jpg" alt="" />
                    </div>
                    <h4>Hand Matter</h4>
                    <span className="">Clothing Brand</span>

                </div>

                <div className="details">
                    <div className="details-box">
                        <span className="f14">Earned This Month</span>
                        <h3>$3829</h3>
                    </div>

                    <div className="details-box">
                        <span className="f14">Active Influencer</span>
                        <h3>75</h3>
                    </div>

                    <div className="details-box">
                        <span className="f14">Active Campaigns</span>
                        <h3>90</h3>
                    </div>

                    <div className="details-box">
                        <span className="f14"> Payout Debt</span>
                        <h3><span>$</span>570</h3>
                    </div>
                </div>

                <div className="btn-section">
                    <CommonButton name="See Reports" onClick={newSalesLink} />
                </div>


            </CardBox>
        </>
    );
}

export default SideNav;