import React from 'react'


const FromInput = (props) => {
    return (
        <>
            <div className= {"form-group "+props.className} >     
            <label htmlFor={props.for}>{props.name}</label>          
                <input type={props.type} className="form-control formstyle" placeholder={props.placeholder}/>
            </div>
        </>
    );
}


export default FromInput;