import React from "react";
const AddImage = ({ images }) => {
    return (
        <>
            {
                images.map((url) => {
                    return (
                        <div className="image-box">
                            <img src={url} />

                            <span className="close-btn"> + </span>
                        </div>

                    )
                })
            }

        </>
    )
}

export default AddImage;