import React from "react";
import { Link } from "react-router-dom";


const NotificationCom = (props) => {
    return (
        <>        
            <div className="col-lg-4">
                <Link to={{pathname: `/${props.link}` }}>
                    <div className={"card noti-section " + props.className}>
                        <div className="card-header">
                            <div className="card-label">
                                <h4 className="card-title h5">{props.title}</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="fw-bold mb-0 f19">
                                {props.detail}

                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default NotificationCom;