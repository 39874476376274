import React  from "react";


const CommonButton = (props) =>{
    return(
        <>
          <button type={props.type} onClick={props.onClick} link={props.link} className={"btn btnOne " + props.className}>{props.name}</button>
        </>
    );
}

export default CommonButton;