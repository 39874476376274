import React from "react";
import AccountTypeComponents from "./AccountTypeComponents";
import { useNavigate } from "react-router-dom"; 

const AccountType = () => {
    //modal button link
    let navigate = useNavigate(); 

    const InfluencerLink = () =>{ 
      let path = '/app/brandInfo'; 
      navigate(path);
    }


    const profileInfoLink = () =>{ 
        let path = '/app/profile-info'; 
        navigate(path);
      }


    return (
        <>
            
            <main className="d-flex main-bg accountType">
                <div className="container-fluid px50">
                    <div className="row text-center card-bg card-center">

                        <div className="w-100">
                        <div className="col-12">
                            <h1>Select Your Account Type</h1>
                        </div>

                        <div className="col-lg-8 d-flex flex-wrap justify-content-around mx-auto">
                            <AccountTypeComponents name="Brand" click={profileInfoLink} />
                             <AccountTypeComponents name="Influencer" click={InfluencerLink} />
                        </div>
                        </div>

                    </div>
                </div>
            </main>
        </>
    );
}

export default AccountType;