import React from "react";

const IconButton = (props) => {
    return (
        <>
            <button className="icon-btn btn">
                <span className="d-flex align-items-center">
                    <span className="icon  pe-2">
                        {props.icon}
                    </span>
                    <span className="btn-text">
                        {props.text}
                    </span>
                </span>
            </button>
        </>
    );
}

export default IconButton;