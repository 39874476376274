import React, { useState } from "react";
import CommonButton from "../../basic/CommonButton";
import Notification from "../../basic/Notification";
import Header from "../../master/Header";

import { FaHandshake, FaRegClock, FaHourglassHalf } from "react-icons/fa";
import FromInput from "../../forms/FormInput";
import CamaingsSection from "./CamaingsSection";
import SideNav from "../../basic/SideNav";
import Modal from 'react-modal';
import { ActiveCamp } from "../../../../data/ActiveCamp"
import { Link } from "react-router-dom";
import CardBox from "../../basic/CardBox";
import PaginatedItems from '../../basic/PaginatedItems'


const Dashboard = () => {
    const activeCamp = ActiveCamp();

    // modal close function
    const [modalIsOpen, setIsOpen] = useState(true);
    function closeModal() {
        setIsOpen(false);
    }


    //Pageingnation
    const handleOnPageClick = () => {
        console.info("on Page Clicked");
    }

    return (
        <>
            <main className="d-flex main-bg dashboard have-header">
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-2">
                            <SideNav />
                        </div>

                        <div className="col-lg-10 ">
                            <CardBox className=" ">
                                <div className="row">
                                    <div className="col-lg-2 align-self-center text-center">
                                        <h2 className="page-title mb-0">Notification</h2>
                                    </div>
                                    <div className="col-lg-10">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <Link to="/brand/new-sales"> <Notification className="blue" title="New Sales" number="50" icon={<FaHandshake />} /></Link>
                                            </div>

                                            <div className="col-md-4">
                                                <Link to="/brand/pendingProposal"><Notification className="yellow" title="Pending Proposals" number="15" icon={<FaRegClock />} /></Link>
                                            </div>

                                            <div className="col-md-4">
                                                <Link to="/brand/activeCampaings"> <Notification className="pink" title="Campaigns Ending Soon" number="25" icon={<FaHourglassHalf />} /></Link>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </CardBox>


                            <CardBox className="mb-0">
                                <div className="row mb-3">
                                    <div className="col-md-6 align-self-center">
                                        <h2 className="page-title mb-0">Your Active Campaings</h2>
                                    </div>

                                    <div className="col-md-6  ">
                                        <div className="d-sm-flex search-section justify-content-end">
                                            <FromInput className="hide-label mb-0 " placeholder="Search Campaign" />
                                            <CommonButton name="Publish Campaign" />
                                        </div>
                                    </div>

                                </div>

                                <div className="campaings-details">
                                    {
                                        activeCamp.map(item => (
                                         <CamaingsSection key={item.id} {...item} images={item.img} />
                                        ))
                                    }
                                </div>

                                <div className="text-end">
                                <PaginatedItems className="justify-content-end" handlePageClick={handleOnPageClick} pageCount={50} />
                                </div>
                                
                            </CardBox>
                        </div>
                    </div>
                </div>

                <Modal isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal">

                    <p>Before You Can Connect With Influencers Please Pair Your Socias with Your Account</p>
                    <CommonButton name="Connect Instagram" onClick={closeModal} />
                </Modal>
            </main>

        </>
    );
}

export default Dashboard;