export const ActiveCamp = () => {

    const activeCamp = [
        {
            id: 1,
            title: "Promote New Air Jordans Collection",
            date: "2/31/2021 - 2/31/2021",
            pendingProposals: 4,
            sales: 8,
            views: 8,
            conversionRate: 32,
            totalProfit: 457,
            workingOn: 5,
            workingCost: "inf. Comm",
            wrokingCostNum: 3473,
            link: "brand/promoteNewItem",
            img: [
                {
                    id: 1,
                    url: "https://picsum.photos/200/300?random=1"
                },

                {
                    id: 2,
                    url: "https://picsum.photos/200/300?random=2"
                },

                {
                    id: 3,
                    url: "https://picsum.photos/200/300?random=3"
                },

                {
                    id: 4,
                    url: "https://picsum.photos/200/300?random=4"
                },

                {
                    id: 5,
                    url: "https://picsum.photos/200/300?random=5"
                }
            ]
        },


        {
            id: 2,
            title: "Promote New Air Jordans Collection",
            date: "2/31/2021 - 2/31/2021",
            pendingProposals: 4,
            sales: 8,
            views: 8,
            conversionRate: 32,
            totalProfit: 457,
            workingOn: 5,
            workingCost: "inf. Comm",
            wrokingCostNum: 3473,
            link: "brand/promoteNewItem",
            img: [
                {
                    id: 1,
                    url: "https://picsum.photos/200/300?random=1"
                },

                {
                    id: 2,
                    url: "https://picsum.photos/200/300?random=2"
                },

                {
                    id: 3,
                    url: "https://picsum.photos/200/300?random=3"
                },

                {
                    id: 4,
                    url: "https://picsum.photos/200/300?random=4"
                },

                {
                    id: 5,
                    url: "https://picsum.photos/200/300?random=5"
                }
            ]
        },

        {
            id: 3,
            title: "Promote New Air Jordans Collection",
            date: "2/31/2021 - 2/31/2021",
            pendingProposals: 4,
            sales: 8,
            views: 8,
            conversionRate: 32,
            totalProfit: 457,
            workingOn: 5,
            workingCost: "inf. Comm",
            wrokingCostNum: 3473,
            link: "brand/promoteNewItem",
            img: [
                {
                    id: 1,
                    url: "https://picsum.photos/200/300?random=1"
                },

                {
                    id: 2,
                    url: "https://picsum.photos/200/300?random=2"
                },

                {
                    id: 3,
                    url: "https://picsum.photos/200/300?random=3"
                },

                {
                    id: 4,
                    url: "https://picsum.photos/200/300?random=4"
                },

                {
                    id: 5,
                    url: "https://picsum.photos/200/300?random=5"
                }
            ]
        },

        {
            id: 4,
            title: "Promote New Air Jordans Collection",
            date: "2/31/2021 - 2/31/2021",
            pendingProposals: 4,
            sales: 8,
            views: 8,
            conversionRate: 32,
            totalProfit: 457,
            workingOn: 5,
            workingCost: "inf. Comm",
            wrokingCostNum: 3473,
            link: "brand/promoteNewItem",
            img: [
                {
                    id: 1,
                    url: "https://picsum.photos/200/300?random=1"
                },

                {
                    id: 2,
                    url: "https://picsum.photos/200/300?random=2"
                },

                {
                    id: 3,
                    url: "https://picsum.photos/200/300?random=3"
                },

                {
                    id: 4,
                    url: "https://picsum.photos/200/300?random=4"
                },

                {
                    id: 5,
                    url: "https://picsum.photos/200/300?random=5"
                }
            ]
        },

        {
            id: 5,
            title: "Promote New Air Jordans Collection",
            date: "2/31/2021 - 2/31/2021",
            pendingProposals: 4,
            sales: 8,
            views: 8,
            conversionRate: 32,
            totalProfit: 457,
            workingOn: 5,
            workingCost: "inf. Comm",
            wrokingCostNum: 3473,
            link: "brand/promoteNewItem",
            img: [
                {
                    id: 1,
                    url: "https://picsum.photos/200/300?random=1"
                },

                {
                    id: 2,
                    url: "https://picsum.photos/200/300?random=2"
                },

                {
                    id: 3,
                    url: "https://picsum.photos/200/300?random=3"
                },

                {
                    id: 4,
                    url: "https://picsum.photos/200/300?random=4"
                },

                {
                    id: 5,
                    url: "https://picsum.photos/200/300?random=5"
                }
            ]
        },
    ]

    return activeCamp;

}


 