import React, { useState } from "react";
import CardBox from "../../basic/CardBox";
import Header from "../../master/Header";
import BackButton from "../../basic/BackButton";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import NotificationCom from "../performance/NotificationCom";
import { FaFilter } from "react-icons/fa";
import PerformanceCampaignsHIstoryCom from "./PerformanceCampaignsHIstoryCom";
import { CampaignsHistoryData } from "../../../../data/CampaignsHistoryData" 



const CampaignsHistory = () => {
   
    const activeCamp = CampaignsHistoryData();

    /* Date calendra*/
    const [value, onChange] = useState([new Date(), new Date()]);

    return (
        <>
            <main className="d-flex main-bg performanceCampHIstory have-header">
                <Header />
                <div className="container-fluid px50">
                    <BackButton />
                    <CardBox>
                        <div className="row">
                            <div className="col-12">
                                <h2 className="page-title">Campaigns History</h2>
                            </div>
                            <NotificationCom title="Total Profit" detail="$3,265.720" className="pink" link="#" />
                            <NotificationCom title="Influnecers Commissions" detail="$3,265.720" className="purpel" link="#" />
                            <NotificationCom title="Total Campaigns Completed" detail="75" className="yellow" link="#" />
                        </div>
                    </CardBox>


                    <CardBox className="overflow-inherit ">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="filter d-flex pb-3">
                                            <DateRangePicker onChange={onChange} value={value} />
                                            <div className="filert-btn">
                                                <FaFilter />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 campaings-details">
                                {
                                    activeCamp.map(item => (
                                        <PerformanceCampaignsHIstoryCom key={item.id} {...item} images={item.img} />
                                    ))
                                }
                            </div>
                        </div>
                    </CardBox>


                </div>
            </main>
        </>
    );
}
export default CampaignsHistory;