import React from "react";

const FromInputFile = (props) => {
    return (
        <>
            <div className="form-group">
                <label for={props.for}>{props.name}</label>
                <input type="file" className="form-control formstyle" id="" placeholder={props.placeholder} />
            </div>
        </>
    );
}

export default FromInputFile;