import React, { useState } from "react";
import Header from "../../master/Header";
import BackButton from "../../basic/BackButton";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { FaEnvelope, FaInstagram } from "react-icons/fa";
import CommonButton from "../../basic/CommonButton";
import PendingProposalByCom from "./PendingProposalByCom";
import Modal from 'react-modal';
import CardBox from "../../basic/CardBox";

const PendingProposalBy = () => {

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    //modal button link
    let navigate = useNavigate();
    const routeChange = () => {
        let path = '/brand/pendingProposal';
        navigate(path);
    }


    //demo object u can remove

    let detailItem = [
        {
            id: 1,
            img: "/images/user.png",
            title: "Adidas Joggin",
            sales: 6,
            engament: 45,
        },

        {
            id: 2,
            img: "/images/user.png",
            title: "Adidas Joggin",
            sales: 2,
            engament: 66,
        },

        {
            id: 3,
            img: "/images/user.png",
            title: "Adidas Joggin",
            sales: 98,
            engament: 63,
        },
    ]

    return (
        <>
            <main className="d-flex main-bg pendingprosal have-header">
                <Header />
                <div className="container-fluid px50">
                   
                        <Link to="/brand/pendingProposal"> <BackButton /> </Link>
           
                    <CardBox>
                        <div className="col-12">
                            <h2 className="page-title mb-0"><span>Proposal By</span> Miguel Sanchez </h2>
                            <span>on <strong>Air jodan</strong> Campaign</span>
                        </div>
                    </CardBox>
       
                        <div className="row">


                            <div className="col-lg-7">
                                <CardBox className="h-100 mb-0">
                                <div className="row  ">
                                    <div class="col-lg-6">
                                        <div class="img-profile">
                                            <img src="/images/user.png" alt="" />
                                            <div class="media-text">
                                                <h3>Miguel Sanchez</h3>
                                                <span class="follower"><span>4.7k</span> Follower </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <ul className="list-unstyled mb-0">
                                            <li> <FaEnvelope /> <span>Milkeinfu@gmail.com</span> </li>
                                            <li> <FaInstagram /> <span>@Miguelitio.caco</span> </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="message-section">
                                            <h4>Message</h4>
                                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellat vel aperiam eius voluptatem atque culpa, minus officia vero, harum veritatis esse error delectus quo nam blanditiis suscipit tempore. Neque, voluptatibus?</p>
                                        </div>
                                    </div>

                                    <div className="btn-section">
                                        <CommonButton onClick={openModal} name="Accept" />
                                        <CommonButton name="Reject" onClick={routeChange} />
                                    </div>
                                </div>
                                </CardBox>
                            </div>

                            <div className="col-lg-5">
                                <CardBox className="mb-0">
                                <div className="right-section">
                                    <h2> Miguel Sanchez Work </h2>

                                    <div className="right-inner-section">
                                        <div className="row">
                                            {
                                                detailItem.map(items => (
                                                    <PendingProposalByCom key={items.id} {...items} />
                                                ))

                                            }

                                        </div>
                                    </div>
                                </div>
                                </CardBox>
                            </div>

                     

                    </div>
                </div>

                <Modal isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                    ariaHideApp={false}>
                    <h2>You accepted migule's Proposal</h2>
                    <p>Are you sure that you want to work with him ?</p>
                    <CommonButton name="Yes I'm Sure" onClick={routeChange} />
                    <CommonButton name="No, I changed my mind " onClick={closeModal} />
                </Modal>
            </main>
        </>
    );
}

export default PendingProposalBy;