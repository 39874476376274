import React from "react";


const PerformanceCampaignsHIstoryCom = (props) => {
    var images = props.images;


    return (
        <>
            <div className="campaings-section">
                <div className="capm-name">
                    <h4> {props.title}</h4>
                    <span className="date">{props.date}</span>
                </div>
                <div className="camp-post-section">
                    <ul className="list-unstyled camp-post">

                        <li>
                            <h6 className="head">Sales</h6>
                            <p className="num">{props.sales}</p>
                        </li>

                        <li>
                            <h6 className="head">Total Profit</h6>
                            <p className="num">${props.totalProfit}</p>
                        </li>
                    </ul>
                </div>

                <div className="working-section">
                    <div className="woring-main">
                        <h6 className="woring-on">Participants</h6>
                        <div className="img-section">
                            {
                                images.map(imgItem => (
                                    <div className="img-area" key={imgItem.id} >
                                        <img src={imgItem.url} alt="" />
                                        <h6 className="name">{imgItem.name}</h6>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className="working-cost">
                        <h6>Commissions</h6>
                        <p className="num">${props.wrokingCostNum}</p>
                    </div>
                </div>

            </div>

        </>
    );
}

export default PerformanceCampaignsHIstoryCom;
