import React from "react";
import { FaBell, FaCog, FaHeadset, FaSignOutAlt } from "react-icons/fa";
 

import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <a href="#" className="navbar-brand">
                            <img src="/images/logo.png" alt="" />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink to="/brand/dashboard">
                                       <span  className="nav-link " aria-current="page">Dashboard</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/brand/Influencers">
                                        <span className="nav-link">Influencers</span>
                                    </NavLink>
                                </li>

                                <li className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" id="campaigns" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Campaigns
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="campaigns">
                                        <li><a href="#" className="dropdown-item">Create New</a></li>
                                        <li>  <NavLink to="/brand/activeCampaings"><span className="dropdown-item">Active Campaign</span></NavLink></li>
                                        <li> <NavLink to="/brand/pendingProposal"><span className="dropdown-item">Proposals Recieved</span></NavLink></li>
                                    </ul>
                                </li>

                                <li className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" id="performanceNav" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Performance
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="performanceNav">
                                        <li>   <NavLink to="/brand/PerformanceOverview">  <span className="dropdown-item">Overview</span></NavLink></li>
                                        <li><a href="#" className="dropdown-item">Payout History</a></li>
                                        <li> <NavLink to="/brand/CampaignsHistory"><span className="dropdown-item">Campaigns Historty</span></NavLink> </li>
                                    </ul>
                                </li>
                            </ul>

                            <ul className="list-unstyled notification-section">
                                <li>
                                    <a href="#" className="nav-link icon"><FaBell /></a>
                                </li>

                                <li className="nav-item dropdown">
                                    <a href="#" className="nav-link dropdown-toggle" id="profileDropddown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className="user-profile">
                                            <img src="/images/user_man.jpg" alt="" />
                                        </span>
                                    </a>
                                    <ul className="dropdown-menu" aria-labelledby="profileDropddown">
                                        <li><a href="#" className="dropdown-item"><FaCog /> Settings</a></li>
                                        <li><a href="#" className="dropdown-item"><FaHeadset />Help Center</a></li>
                                        <li><a href="#" className="dropdown-item"><FaSignOutAlt /> Log Out</a></li>
                                    </ul>
                                </li>


                            </ul>
                        </div>
                    </div>
                </nav>
            </header>

        </>
    );
}

export default Header;