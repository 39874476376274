import React from 'react'
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const BackButton = (props) =>{

    return(
        <>
             <button type="button" className='back-btn'>  <span className='text-back'>Back</span> <MdOutlineArrowBackIosNew/> </button>
        </>
    );

}


export default BackButton;