import React from "react";


const FormTextarea =(props) =>{
  return(
      <>
          <div className="form-group">
                <label for={props.for}>{props.name}</label>
                <textarea className="form-control formTextarea" placeholder={props.placeholder}></textarea>
            </div>
      </>
  );
}

export default FormTextarea;