import React from "react";
import CommonButton from "../ui/basic/CommonButton";
import FromInput from "../ui/forms/FormInput";
import {Link} from "react-router-dom";

const Login = (props) => {
    return (
        <>
            <section className="login-bg">
                <div className="login-area text-center">
                    <h1>Login</h1>
                    <FromInput type="text" name="Username" placeholder="Username"/>
                    <FromInput type="password" name="Password" placeholder="Password"/>
                    <Link to="/brand/dashboard"><CommonButton type="button" name="Login"/></Link>
                    <p className="account">Don't Have An Account? <Link to="/app/register"> Register</Link></p>
                </div>
            </section>
        </>
    );
}

export default Login;