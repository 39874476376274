import React from "react";
import { Link } from "react-router-dom";
const PendingProposalComp = (props) => {
    return (
        <>
            <div className="col-md-3">
                <Link to={{pathname:`/${props.link}`}}>
                    <div className="pendingpropsal-main">
                        <img src={props.img} alt="" />
                        <div className="text-area">
                            <h2>{props.title}</h2>
                            <p className="comission">Comission Of <strong>{props.comission}%</strong></p>
                            <span className="date"> Ends <strong>{props.date}</strong></span>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}


export default PendingProposalComp;