import React from "react";
import Select from 'react-select'

const SelectOption = (props) => {
    return (
        <>
            <div className={"form-group " + props.className}>
                <label>{props.label}</label>
                <Select options={props.options} />
            </div>
        </>
    );
}

export default SelectOption; 