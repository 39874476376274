import React from "react";

const SalesComp = (props) => {
    return (
        <>
            <div className="salesSection">
                <div className="row">
                    <div className="col">
                        <div className="img-profile">
                            <img src="/images/user.png" alt="" />
                            <div className="media-text">
                                <h3>{props.name}</h3>
                                <span className="follower"><span>{props.follower}</span> Follower </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 align-self-center">
                        <ul className="list-unstyled pro-detail">
                            <li>
                                <h3>Product</h3>
                                <p>{props.product}</p>
                            </li>

                            <li>
                                <h3>Product Price</h3>
                                <p>${props.productPrice} </p>
                            </li>

                            <li>
                                <h3>Influencer Commission</h3>
                                <p>$ {props.debt}</p>
                            </li>

                            <li>
                                <h3>Sold</h3>
                                <p>{props.date}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SalesComp;
