import React from "react";
import { FaTag } from "react-icons/fa";
 

const AccountTypeComponents = (props) =>{
    return(
        <>
           <div className="select-account" onClick={props.click}>
               <div className="select-image">
                  <FaTag />
               </div>
               <div className="select-name">
                    <h2>{props.name}</h2>
               </div>
            </div>     
        </>
    );
}

export default AccountTypeComponents;