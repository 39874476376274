import React from "react";
import { Link } from "react-router-dom";
import BackButton from "../../basic/BackButton";
import Header from "../../master/Header";
import MyInfluencersComp from "./MyInfluencersComp";
import { GoSearch } from "react-icons/go";


const MyInfluencers = () => {

    let users = [
        {
            id: 1,
            link: "",
            image: "/images/user_man.jpg",
            name: "Meguel Sanchez",
            location: "Buenos Aires, Argentina",
            email: "Mikeinflu@gmail.com",
            instaid: "@migulito.capo",
        },

        {
            id: 2,
            link: "",
            image: "https://picsum.photos/200/300?random=3",
            name: "James Sanchez",
            location: "Buenos Aires, Argentina",
            email: "Mikeinflu@gmail.com",
            instaid: "@migulito.capo",
        },

        {
            id: 3,
            link: "",
            image: "https://picsum.photos/200/300?random=1",
            name: "Robert Sanchez",
            location: "Buenos Aires, Argentina",
            email: "Mikeinflu@gmail.com",
            instaid: "@migulito.capo",
        },

        {
            id: 4,
            link: "",
            image: "https://picsum.photos/200/300?random=2",
            name: "John Sanchez",
            location: "Buenos Aires, Argentina",
            email: "Mikeinflu@gmail.com",
            instaid: "@migulito.capo",
        },

        {
            id: 5,
            link: "",
            image: "https://picsum.photos/200/300?random=5",
            name: "Meguel Sanchez",
            location: "Buenos Aires, Argentina",
            email: "Mikeinflu@gmail.com",
            instaid: "@migulito.capo",
        },

        {
            id: 6,
            link: "",
            image: "https://picsum.photos/200/300?random=6",
            name: "James Sanchez",
            location: "Buenos Aires, Argentina",
            email: "Mikeinflu@gmail.com",
            instaid: "@migulito.capo",
        },

        {
            id: 7,
            link: "",
            image: "https://picsum.photos/200/300?random=7",
            name: "Robert Sanchez",
            location: "Buenos Aires, Argentina",
            email: "Mikeinflu@gmail.com",
            instaid: "@migulito.capo",
        },

        {
            id: 4,
            link: "",
            image: "https://picsum.photos/200/300?random=8",
            name: "John Sanchez",
            location: "Buenos Aires, Argentina",
            email: "Mikeinflu@gmail.com",
            instaid: "@migulito.capo",
        },
    ]


    return (
        <>
            <main className="d-flex main-bg myinfluencers have-header">
                <Header />
                <div className="container-fluid px50">
                    <Link to="/brand/dashboard"> <BackButton /> </Link>
                    <div className="card-bg">
                        <div className="row">
                            <div className="col-md-8">
                                <h2 className="page-title">My Influencer</h2>
                            </div>
                            <div className="col-md-4">
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control formstyle" placeholder="Search"/>
                                    <span className="input-group-text" id="basic-addon2"><GoSearch/></span>
                                </div>
                            </div>
                        </div>



                        <div className="influencers-section">
                            <div className="row">
                                {
                                    users.map(user =>
                                    (
                                        <MyInfluencersComp {...user} key={user.id} />
                                    ))
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </>
    );
}

export default MyInfluencers;