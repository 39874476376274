import React from "react";

const CardBox = (props) =>{

   

    return(
        <>
          <div className= {"comman-card "+props.className}>
               {props.children}
          </div>
        </>
    );

}

export default CardBox;