import React from "react";
import BackButton from "../../basic/BackButton";
import CheckAmination from "../../basic/CheckAmination";
import CommonButton from "../../basic/CommonButton";
// import AddYourCard from "../addYourCard/AddYourCard";
import { useNavigate } from "react-router-dom"; 
import { Link } from "react-router-dom";

const ShopifyIntergration = () => {

     // button link
   let navigate = useNavigate(); 

   const AddYourCard = () =>{ 
     let path = '/brand/addYourCard'; 
     navigate(path);
   }
    return (
        <>
            <main className="main-bg shopify d-flex">
                <section className="w-100 align-self-center">
                    <div className="container-fluid px50">
                        <div className="row">                             
                            <Link to="/brand/sripe-account"> <BackButton /> </Link>                         
                            <div className="col-lg-12 mx-auto">
                                <div className="card-bg text-center card-center">
                                    <div className="w-100">
                                        <CheckAmination />

                                        <h1>Shopify Intergration Sucessfull</h1>

                                        <CommonButton name="Next" onClick={AddYourCard} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}


export default ShopifyIntergration;